h1 {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.row {
  display: flex;
  margin: 0 auto;
}
.board {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.col {
  font-weight: bold;
  height: 30px;
  width: 30px;
  border: 1px solid rgb(167, 166, 166);
  box-sizing: border-box;
  padding: 5px;
  background: rgb(214, 214, 214);
}

.col.not-open {
  border: 3px outset rgb(231, 231, 231);
}

.col.color-2 {
  color: green;
}
.col.color-1 {
  color: blue;
}
.col.color-3 {
  color: red;
}
.col.color-4 {
  color: purple;
}
.col.color-5 {
  color: brown;
}

.level-select {
  width: 50px;
}

.fork-link {
  position: fixed;
  left: 0;
  top: 0;
}
